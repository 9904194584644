import React, { Fragment, useState, useRef, useEffect } from 'react';
import {Col, FormGroup, Input, Label, Media, Row} from "reactstrap";
import {
    checkTitle
} from "../../store/prodcutSlice";
import { useDispatch } from 'react-redux';
import DropZone from "../../../../share-components/upload/dropzone";
import { getInventoryProducts, updateInventoryBulk } from '../../store/inventorySlice';
import Typography from '@material-ui/core/Typography';

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};
const bulkUpload = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();
    const { errors, control, register,setValue,trigger,roles,getValues } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [imagePreloader, setImageLoader] = useState(false);
   
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loading, isSetLoading] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [uploadFile, setUploadFile] = useState(null);

    const onChangeFile = e => {
        setImageLoader(true);
		setUploadFile(e);
        setValue('file',e[0]);
        setImageLoader(false);
	};

    return (
        <Fragment>
            <FormGroup>
                <div className="product-box">
                    <div className="product-img pt-3 pb-3">
                        {imagePreloader&& <div className="loader-box">
                            <div className="loader-2"></div>
                        </div>}
                        {!imagePreloader&& <DropZone 
                                        extension={".csv"}
                                        onChangeFile={onChangeFile}></DropZone>}
                    </div>
                    {uploadFile ? (
								
                        <Typography>File: {`${uploadFile[0].name}`}{' '}</Typography>

                    ) : (
                        ''
                    )}
                </div>
            </FormGroup>         
        </Fragment>
    );
}

export default bulkUpload;