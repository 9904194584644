import React, {Fragment, useEffect, useRef, useState} from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {defaultValues} from "../settings";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {addSellerLead} from "../store/sellerLeadSlice";
import {mapSellerLeadResponseToContactDetailsForm} from "../../../utils/mapper";
import {useHistory} from "react-router-dom";
import {addBuyerLead, setSaveState} from "../store/buyerLeadSlice";
import {toast} from "react-toastify";

const phoneRegExp =/^[0-9]{9,10}$/
yup.addMethod(yup.string, "customPhoneValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        if(value){
            return  value.match(phoneRegExp)?true:false;
        }
        return  false;
    });
});

export const schema = yup.object().shape({
    // contact_name: yup.string().required("Contact name is required").typeError('Contact name 1 is required'),
    first_name: yup.string().required("First name is required").typeError('First name is required'),
    last_name: yup.string().required("Last name is required").typeError('Last name is required'),
    mobile: yup.string().min(9, 'Invalid Mobile number')
        .max(10, 'Invalid Mobile number').required("Mobile Number is required").typeError('Mobile Number is required').customPhoneValidation('You must enter valid phone number'),
    // whatssapp: yup.string().required("WhatsApp Number is required").typeError('WhatsApp Number is required'),
    contact_email: yup.string().required("Email is required").typeError('Email is required'),
    // confirm_email:yup.string().required("Confirm Email is required").typeError('Confirm Email is required'),
});

export const ContactDetails = (props) => {
    const {sellerLeadState, id, VerticleTab, setVerticleTab, formData, buyer} = props;

    const {handleSubmit, formState, reset, register, trigger, setValue, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });

    const [actionBtn, setActionBtn] = useState(0);
    const [isWhattssappSame, setIsWhattssappSame] = useState(false);
    const designations = [];
    const dispatch = useDispatch();
    const {errors} = formState;
    const submitBtnRef = useRef();
    let history = useHistory();
    const submitBtnSaveRef = useRef();

    const setDesignation = (e, id) => {
        if (e.target.checked) {
            designations.push(id)
        } else {
            const index = designations.indexOf(id);
            if (index > -1) {
                designations.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setValue("designations", designations);
    }


    const onSubmitForm = (data) => {
        submitBtnSaveRef.current.disabled=true;
        const finalData = {
            id: id,
            formData: data,
            type: 4

        }
        if (buyer) {
            dispatch(addBuyerLead({...finalData})).then(res => {
                submitBtnSaveRef.current.disabled=false;
                if (!res.errors)
                    if (!res.payload.error) {
                        if (actionBtn == 0) {
                            toast.success("Contact Details Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                            setVerticleTab((parseInt(VerticleTab) + 1).toString());
                            dispatch(setSaveState(true));
                        } else {
                            history.push({
                                pathname: '/leads/buyer-lead',
                                state: { from: "submit" }
                            });
                        }
                    } else {
                        toast.error(res.payload.data, {position: toast.POSITION.TOP_RIGHT});
                    }
            });
        } else {
            dispatch(addSellerLead({...finalData})).then(res => {
                submitBtnSaveRef.current.disabled=false;
                if (!res.errors)
                    if (!res.payload.error) {
                        if (actionBtn == 0) {
                            toast.success("Contact Details Updated successfully", {position: toast.POSITION.TOP_RIGHT});
                            setVerticleTab((parseInt(VerticleTab) + 1).toString());
                        } else {
                            history.push('/leads/seller-lead');
                        }
                    } else {
                        toast.error(res.payload.data, {position: toast.POSITION.TOP_RIGHT});
                    }
            });
        }
    }

    useEffect(() => {
        if (id) {
            if (formData.length != 0) {
                if (actionBtn == 0) {
                    reset(mapSellerLeadResponseToContactDetailsForm(formData));
                    if (formData.mobile == formData.whatssapp) {
                        setIsWhattssappSame(true)
                    } else {
                        setIsWhattssappSame(false)
                    }
                } else {
                    history.push('/leads/seller-lead');
                }
            }
        }

    }, [formData]);

    return (
        <Fragment>
            <Form onSubmit={handleSubmit(onSubmitForm)}>
                <Row>
                    {/*<Col md={6}>*/}
                    {/*    <FormGroup>*/}
                    {/*        <Label className="form-label">Primary Contact Name</Label>*/}
                    {/*        <input name="contact_name" type="text"*/}
                    {/*               className={`form-control ${errors.contact_name ? 'is-invalid' : ''}`}*/}
                    {/*               placeholder="John Doe" {...register('contact_name')}  />*/}
                    {/*        <div className="invalid-feedback">{errors.contact_name?.message}</div>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Title</Label>
                            <Select placeholder="Mr." getOptionLabel={(option) => option.name}
                                    value={getValues('title')}
                                    getOptionValue={(option) => option.id} {...register("title")} onChange={(e) => {
                                setValue('title', e);
                                trigger('title');
                            }} className={` ${errors.title ? 'is-invalid' : ''}`}
                                    options={sellerLeadState.settings.titles}/>
                            <div className="invalid-feedback">{errors.title?.message}</div>
                        </FormGroup>
                    </Col>

                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">First Name</Label>
                            <input name="first_name" type="text"
                                   className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                                   placeholder="John Doe" {...register('first_name')}  />
                            <div className="invalid-feedback">{errors.first_name?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Last Name</Label>
                            <input name="last_name" type="text"
                                   className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                                   placeholder="John Doe" {...register('last_name')}  />
                            <div className="invalid-feedback">{errors.last_name?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <Label className="form-label">Designation</Label>
                        <FormGroup className="m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                            {sellerLeadState.settings.designations?.map((item, index) =>
                                <div key={index} className="checkbox checkbox-dark">
                                    <input defaultChecked={getValues("designations")?.includes(item.id)}
                                           name={item.value} id={"inline-" + item.value} type="checkbox"
                                           onChange={(e) => {
                                               setDesignation(e, item.id)
                                           }}/>
                                    <Label for={"inline-" + item.value}><span
                                        className="digits">{item.name}</span></Label>
                                </div>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={6} className="mt-1">
                        <Label className="form-label">Mobile</Label>
                        <FormGroup className="input-group">

                            <span className="input-group-text">{sellerLeadState.dialCode}</span>
                            <input name="mobile" type="text"
                                   className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                                   placeholder="Mobile Number" {...register('mobile')}  />
                            <div className="invalid-feedback">{errors.mobile?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className="m-t-5 m-checkbox-inline mb-0 custom-radio-ml">
                            <Label className="form-label">WhatsApp/ Viber Number</Label>
                            <input defaultChecked={getValues('whatssappSame')} className="m-l-30" name="same_as_mobile" id="inline-10" type="checkbox"
                                   onChange={(e) => {
                                       if (e.target.checked) {
                                           setValue('whatssapp',getValues('mobile'))
                                       } else {
                                           setValue('whatssapp','')
                                       }
                                   }}/>
                            <Label for="inline-13"><span
                                className="digits">Same As Mobile</span></Label>
                            <input name="whatssapp" type="text"
                                   className={`form-control ${errors.whatssapp ? 'is-invalid' : ''}`}
                                   placeholder="John Doe" {...register('whatssapp')}  />
                            <div className="invalid-feedback">{errors.whatssapp?.message}</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className="form-label">Email</Label>
                            <input name="contact_email" type="text"
                                   className={`form-control ${errors.contact_email ? 'is-invalid' : ''}`}
                                   placeholder="John Doe" {...register('contact_email')}  />
                            <div className="invalid-feedback">{errors.contact_email?.message}</div>
                        </FormGroup>
                    </Col>
                    {/*<Col md={6}>*/}
                    {/*    <FormGroup>*/}
                    {/*        <Label className="form-label">Confirm Email</Label>*/}
                    {/*        <input name="confirm_email" type="text"*/}
                    {/*               className={`form-control ${errors.confirm_email ? 'is-invalid' : ''}`}*/}
                    {/*               placeholder="John Doe" {...register('confirm_email')}  />*/}
                    {/*        <div className="invalid-feedback">{errors.confirm_email?.message}</div>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                </Row>
                <div className="mt-2 d-flex justify-content-end">
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={() => {
                            setActionBtn(1)
                        }} className="btn btn-danger btn-block">Save & Exit
                        </button>
                    </div>
                    <div className="p-2">
                        <button ref={submitBtnSaveRef} onClick={() => {
                            setActionBtn(0)
                        }} className="btn btn-primary btn-block">Save & Next
                        </button>
                    </div>
                </div>
            </Form>
        </Fragment>
    );
}