import React, {Fragment} from "react";
import {Row, Col, FormGroup, Label} from "reactstrap";
import {FormCheck} from "react-bootstrap";

function ProductPlatformForm(props) {

    const {
        errors,
        control,
        register,
        setValue,
        trigger,
        getValues,
        searchSellersByName,
        sellers,
        setOptions,
        promiseOptions
    } = props;

  return (
    <Fragment>
            <Row>
                <Col>
                   <FormGroup>
                       <Label className="col-form-label">Product Platform Name</Label>
                       <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`}/>
                       <div className="invalid-feedback">{errors.name?.message}</div>
                   </FormGroup>
                </Col>
            </Row>
            
        </Fragment>
  )
}

export default ProductPlatformForm




