import React, {Fragment, useEffect, useRef, useState} from "react";
import {Col, Label} from "reactstrap";
import JqxTable from "../../../../../share-components/table/JqxTable";

import * as ReactDOM from "react-dom";
import Select from "react-select";
import {toast} from "react-toastify";
import {singleCalculation, singlePackCalculation} from "./settings/calculation";

const SizeRatioJqx = (props) => {
    const {errors, register, setValue, trigger, getValues, productState} = props;
    let gridRef = useRef(null);
    const [ratioData,setRatioData]=useState([]);
    const [columns,setColumns]=useState([]);
    const [datafields,setDatafields]=useState([]);


    useEffect(()=>{
        let sizeDatafields = [];
        let sizeColumns=[];
        let sizeRatioDataObj={};
        let sizeRatioDataArray=[];
       getValues('size').map((item, index) => {
           sizeDatafields.push({name: item.id, type: 'number'});
           sizeRatioDataObj[item.id]=item.ratio;
           sizeColumns.push(   {
               text: item.name,
               datafield: item.id,
               editable: true,
               columntype: 'text',
               cellsalign: 'right',
               align:'center',
               cellendedit: function (row, datafield, columntype, oldvalue, value) {
                   let sizeData=getValues('size');
                 let sizeDataIndex=sizeData.findIndex(x => x.id ===datafield);
                 sizeData[sizeDataIndex].ratio=Number(value);
                 setValue('size',sizeData);

                 const ratioNotUpdated=getValues('size').findIndex(x => x.ratio ==0);
                 if(ratioNotUpdated == -1){
                     setValue('value_change',datafield+"_"+value);
                     trigger('size');
                 }


                   // gridRef.setcellvalue(row, datafield, value);
               }

           })

       });
       sizeRatioDataArray.push(sizeRatioDataObj);
       setDatafields(sizeDatafields);
       setColumns(sizeColumns);
       setRatioData(sizeRatioDataArray);

       },[getValues('size')])




    const getJqxRef = jqx => {
        if (jqx) {
            gridRef = jqx;

            setTimeout(
                () => {
                    if(gridRef) {
                        const myEle = document.getElementById(gridRef._id);
                        if(myEle) {
                            gridRef.updatebounddata();
                        }
                    }

                    // myGrid.updatebounddata();
                },
                1000
            );
        }
    };


    const cellEdit = event => {
        // let ratioData=getValues('size');
        //
        // const gridInformation = gridRef.getdatainformation();
        // // eslint-disable-next-line no-plusplus
        //
        //     const rowData = gridRef.getrowdata(0);
        //      ratioData.map((create, index) => {
        //         create.ratio=rowData[create.id]
        //     });
        //     // compositionData.push(rowData)
        //
        //setValue('size',compositionData);

    };

    return (
        <Fragment>
            {ratioData.length>0 && columns.length>0 && datafields.length>0 && <div className="form-row mb-3" >
            <Col md="12">
                <Label className="col-form-label " >Size Ratio</Label>
                <div style={{width: '100%'}}>
                  <JqxTable
                        datafields={datafields}
                        columns={columns}
                        data={ratioData}
                        myGrid={gridRef}
                        getJqxRef={getJqxRef}
                        isDelete={false}
                        onCellendedit={cellEdit}
                        disableCreateRow
                    />
                </div>
            </Col>

        </div>}
        </Fragment>
    );
}
function areEqual(prevProps, nextProps) {

    return JSON.stringify(prevProps.memoData) == JSON.stringify(nextProps.memoData);
    /*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
}
export default React.memo(SizeRatioJqx, areEqual) ;
