import API from "../../../utils/api";


export const getProductPlatformsApi = async (data) => {
    const response = await API.get('product-platform/product-platform', { params: data });
    return { response };
};

export const addProductPlatformApi = async location => {
    const response = await API.post('product-platform/product-platform', location);
    return { response };
}

export const updateProductPlatformApi = async (location, id) => {
    const response = await API.put(`product-platform/product-platform/${id}`, location);
    return { response };
};

export const deleteProductPlatformApi = async (id) => {
    const response = await API.delete(`product-platform/product-platform/${id}`);
    return { response };
};
