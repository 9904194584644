import {Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table,Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,} from "reactstrap";
import React, {Fragment, useState} from "react";
import {downloadOrderInvoice, downloadOrderPdf, unSetTrackingData} from "../../store/orderSlice";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";


export const Item = (props) => {
    const {orderState,orderId,retrieveTrackingDetails,authData}=props;
    const dispatch = useDispatch();
    const currentUser = useSelector(({authReducer}) => authReducer.data.user);
    const userState =  useSelector(({authReducer}) => authReducer.data);
    const processPDFDownload =(name)=>{
        const data={
            id:orderId,
            params: {
                name:name,
                seller:authData.defaultSeller?.value}
        }
        dispatch(downloadOrderPdf(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${orderState.data?orderState.data.order_name:"-"}_${name.replace(/\s+/g, '-')}_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const handlePackageSlipDownload =()=>{
        processPDFDownload("Package Slip");
    }
    const handlePickingTicketDownload =()=>{
        processPDFDownload("Picking Ticket");
    }
    const handleInvoiceDownload =()=>{
        const data={
            id:orderId,
            params: {
                seller:authData.defaultSeller?.value}
        }
        dispatch(downloadOrderInvoice(data)).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${orderState.data?orderState.data.order_name:"-"}_Invoice_${(new Date().toJSON().slice(0,10))}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    }
    const noTracking =()=>{
        dispatch(unSetTrackingData());
        toast.warning("Tracking details are required to be added to this item", {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    // const [dropdownOpen, setDropdownOpen] = useState(false);

    // const toggle = () => setDropdownOpen((prevState) => !prevState); 
    return (
        <Fragment>
            <Col sm="12">
                <Card className="ribbon-wrapper">
                    <CardBody>
                        <div className="ribbon ribbon-clip h5 ribbon-dark">Items from Order {orderState.data?orderState.data.order_name:"-"}</div>
                        {orderState.data?orderState.data.customer_name!=="-" && <div className="mt-2 d-flex justify-content-end">
                            <div className="p-2">
                                <button onClick={handleInvoiceDownload} className="btn btn-success btn-block">Invoice</button>
                            </div>
                            {(currentUser.permission).includes('Create Packing Slips') && <div className="p-2">
                                <button onClick={handlePackageSlipDownload} className="btn btn-danger btn-block">Packing Slip</button>
                            </div>}
                            {(currentUser.permission).includes('Create Picking Ticket') && <div className="p-2">
                                <button onClick={handlePickingTicketDownload} className="btn btn-primary btn-block">Picking Ticket</button>
                            </div>}
                            {/* <div className="p-2">
                            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret color="primary" className="btn btn-primary">Supplier</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>Foo Action</DropdownItem>
                                    <DropdownItem>Bar Action</DropdownItem>
                                    <DropdownItem>Quo Action</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            </div> */}
                        </div>:""}

                        <div className="table-responsive">
                            <Table>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'40%' }}>Product</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'15%' }}>Status</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'15%' }}>Return Status</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'10%', textAlign: 'right' }}>Quantity</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'10%', textAlign: 'right' }}>Price (£)</th>
                                        <th scope="col" style={{ whiteSpace: 'nowrap',width:'10%', textAlign: 'right' }}>Total (£)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(orderState.list).length > 0?(orderState.list.map((item,index)=>{
                                    return (<tr key={index}>
                                        <td className="col-2">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img width="40" className="img-fluid" src={item.image}/>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="row">
                                                    <p className={'font-primary'}>{item.sku}</p>
                                                </div>
                                                <div className="row">
                                                    {(() => {
                                                    if (item.status_id===1) {
                                                        return (
                                                            <i onClick={noTracking} style={{margin: 'auto', marginLeft: 0,}} className="fa fa-truck mr-1 text-primary"></i>
                                                        )
                                                    } else if (item.status_id===2) {
                                                        return (
                                                            <a onClick={() => {retrieveTrackingDetails(item.id)}} ><i className="fa fa-truck mr-1 text-success"></i></a>
                                                        )
                                                    } else {
                                                        return (
                                                            <i className="fa fa-truck mr-1 text-dark"></i>
                                                        )
                                                    }
                                                    })()}
                                                    {item.name}
                                                </div>
                                                <div className="row">
                                                    <p>{item.brand}</p>
                                                </div>
                                                <div className="row">
                                                    <p>{item.colour}</p>
                                                </div>
                                                <div className="row">
                                                    <p>{item.size}</p>
                                                </div>
                                            </div>

                                        </div>
                                         
                                        </td>
                                        <td><div style={{marginTop: "2rem"}}>{item.status}</div></td>
                                        <td><div style={{marginTop: "2rem"}}>{item.return_status}</div></td>
                                        <td><div style={{marginTop: "2rem", textAlign: 'right'}}>{item.quantity}</div></td>
                                        <td><div style={{marginTop: "2rem", textAlign: 'right'}}>{item.price}</div></td>
                                        <td><div style={{marginTop: "2rem", textAlign: 'right'}}>{item.total_price}</div></td>
                                    </tr>)
                                    })):null
                                }
                                    <tr></tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="font-weight-bold">Sub Total:</td>
                                        <td><div style={{textAlign: 'right'}}>{orderState.data?orderState.data.total_line_items_amount:""}</div></td>
                                    </tr>
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Discount:</td>
                                        <td><div style={{textAlign: 'right'}}>{orderState.data?orderState.data.total_discount:""}</div></td>
                                    </tr>
                                    {userState.user.role.name !== "Seller" ? (
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Shipping Charge:</td>
                                        <td><div style={{textAlign: 'right'}}>{orderState.data?orderState.data.shipping_charges:""}</div></td>
                                    </tr>
                                    ) : '' }
                                    <tr>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td style={{borderStyle:"none"}}></td>
                                        <td className="font-weight-bold">Total:</td>
                                        <td><div style={{textAlign: 'right'}}>{orderState.data?orderState.data.total_price:""}</div></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};