import * as React from 'react';
import {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import ScrollArea from 'react-scrollbar';
import {Accordion, Card, useAccordionButton} from "react-bootstrap";
import {CardHeader, CardBody, Row, Col, Label} from 'reactstrap'
import {getLogs, setLogs, toggleQuickPanel} from './store/logSlice';
import reducer from './store';
import withReducer from "../../store/withReducer";
import CountryList from "../seller/seller-vat/countryList";
import InitialsAvatar from "react-initials-avatar";


// const useStyles = makeStyles(theme => ({
//     root: {
//         width: 350
//     }
// }));
function CustomToggle({children, eventKey}) {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        setIsOpen(!isOpen)
    );

    return (
        <button
            type="button"
            className="btn btn-primary-outline pull-right"
            onClick={decoratedOnClick}
        >
            {isOpen ? <i className="fa fa-chevron-circle-up"></i> : <i className="fa fa-chevron-circle-down"></i>}
        </button>
    );
}

function LogActivity(props) {
    const dispatch = useDispatch();
    const state = useSelector(({log}) => log.log);
    const {id=null,log}=props;
    // const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    useEffect(() => {
        if (state.drawer.isOpen) {
            const dataObj = {log: state.drawer.log, subject_id: state.drawer.id};
            dispatch(getLogs(dataObj)).then(res => {
                // eslint-disable-next-line array-callback-return
                res.payload.map(n => {
                    if (n.changes.old) {
                    }
                });
                dispatch(setLogs(res.payload));
            });
        }
    }, [dispatch, state.drawer.isOpen]);

    const getObjectKeys = changes => {
        const keys = Object.keys(changes);
        return keys;
    };
    const isSameValue = (oldAttribute, newAttribute) => {
        return oldAttribute === newAttribute;
    };
    const isHasSameValue = (item, attributes, changeItem, nestedIndex) => {
        let unChangedValue = ""
        // eslint-disable-next-line array-callback-return
        attributes.map(n => {
            if (item.changes.old[changeItem][nestedIndex][n] === item.changes.attributes[changeItem][nestedIndex][n]) {
                unChangedValue = {
                    "key": n,
                    "value": item.changes.old[changeItem][nestedIndex][n]
                }
            }

        });
        return unChangedValue;
    };

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <div className="float-right">
                <button className="btn btn-default float-right" onClick={() => {
                    document.querySelector(".customizer-contain").classList.add('open');
                    dispatch(toggleQuickPanel({isOpen:true,id,log}))
                }
                }>
                    <i className="fa fa-clock-o fa-2x"></i>
                </button>
            </div>
            <div className="customizer-contain" >
                <div className="customizer-header">
                    <i className="icon-close" onClick={() => {
                        document.querySelector(".customizer-contain").classList.remove('open');
                        dispatch(toggleQuickPanel({isOpen:false,id:null,log:null}))
                    }
                    }></i>
                    <h5>{"Log Activity"} </h5>
                    <div style={{height: '1000'}}>
                        <ScrollArea
                            horizontal={false}
                            vertical={true}
                        >
                            <Accordion defaultActiveKey="0">
                                {state.logs.map((item, index) =>

                                    <Card key={index} style={{margin: 0}}>
                                        <Card.Header style={{padding: 10}}>
                                            <Row>
                                                <Col  sm={2}>
                                                    <InitialsAvatar className="log-activity-image mt-3" name={item.created_by}/>
                                                </Col>
                                                <Col sm={8}>
                                                    <Label className="form-label mt-4">{item.description}</Label>
                                                </Col>
                                                <Col className="mt-3" sm={2}>
                                                    <CustomToggle eventKey={index}>Click me!</CustomToggle>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col  sm={6}>
                                                    <p>{item.created_by}</p>
                                                </Col>
                                             <Col sm={6} className="float-right">
                                                 {item.date}
                                             </Col>
                                            </Row>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={index}>
                                            <Card.Body>
                                                {item.changes.old && (
                                                    <div>
                                                        {getObjectKeys(item.changes.old).map((changeItem, changeIndex) => (
                                                            <Row className="p-0"  key={changeIndex}>
                                                                <Col md={3} className="p-0">
                                                                    <p><strong>{changeItem}</strong></p>
                                                                </Col>
                                                                <Col  md={9} className="p-0">
                                                                    {Array.isArray(item.changes.old[changeItem]) ? (
                                                                        item.changes.old[changeItem].map(
                                                                            (nestedItem, nestedIndex) =>
                                                                                !Array.isArray(nestedItem) && (
                                                                                    <div
                                                                                        className="mb-2 p-1"
                                                                                        key={nestedIndex}
                                                                                    >

                                                                                            { isHasSameValue(item,getObjectKeys(nestedItem),changeItem,nestedIndex) &&<div className="p-0"
                                                                                                                                                                            key={nestedIndex}>
                                                                                                <div  md={12} className="p-0">
                                                                                                    <p> <div   style={{display:'inline-flex'}}><strong>{isHasSameValue(item,getObjectKeys(nestedItem),changeItem,nestedIndex).key}  :  </strong>
                                                                                                        <span> {isHasSameValue(item,getObjectKeys(nestedItem),changeItem,nestedIndex).value}</span>
                                                                                                    </div>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>}
                                                                                            {getObjectKeys(nestedItem).map(
                                                                                                (nestedObjItem, nestedObjIndex) => (
                                                                                                    <p
                                                                                                        key={nestedObjIndex}

                                                                                                    >   {!isSameValue(item.changes.old[changeItem][nestedIndex][nestedObjItem], item.changes.attributes[changeItem][nestedIndex][nestedObjItem]) && <> <strong>{nestedObjItem}

                                                                                                    </strong> :


                                                                                                        <span
                                                                                                            style={{color: "red"}}> {item.changes.old[changeItem][nestedIndex][nestedObjItem] ? item.changes.old[changeItem][nestedIndex][nestedObjItem] : "∅"} </span>
                                                                                                         -
                                                                                                        <span
                                                                                                            style={{color: "green"}}> {item.changes.attributes[changeItem][nestedIndex][nestedObjItem] ? item.changes.attributes[changeItem][nestedIndex][nestedObjItem] : "∅"}</span> </> }
                                                                                                        {(getObjectKeys(nestedItem)).length - 1 !== nestedObjIndex && <>,</>}
                                                                                                    </p>


                                                                                                )
                                                                                            )}

                                                                                    </div>
                                                                                )
                                                                        )
                                                                    ) : (
                                                                        <div
                                                                            className="mb-2 p-1"
                                                                            key={changeIndex}
                                                                        >
                                                                        <Row>
                                                                            <Col md={5}>
                                                                                <p style={{color: "red"}}>
                                                                                    {item.changes.old[changeItem]
                                                                                        ? item.changes.old[changeItem]
                                                                                        : "∅"}
                                                                                </p>
                                                                            </Col>
                                                                            <Col  md={2}>
                                                                                -
                                                                            </Col>
                                                                            <Col  md={5}>
                                                                                <p style={{color: "green"}}>
                                                                                    {item.changes.attributes[changeItem] ? item.changes.attributes[changeItem] : "∅"}
                                                                                </p>
                                                                            </Col>
                                                                        </Row>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </div>
                                                )}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )}
                            </Accordion>

                            {/*{state.logs.map((item, index) => (*/}
                            {/*    <p>da</p>*/}
                            {/*))}*/}
                        </ScrollArea>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withReducer([{log: reducer}])(memo(LogActivity));
