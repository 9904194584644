import React, {Fragment, useEffect, useRef, useState} from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";

import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../../store/withReducer";
import {
    getAllPendingReturnRequest,
    getOrderSettings, setOrderData,
    setReturnRequestData,
    setSettings, showPendingReturnRequestList,
    showReturnList, storeReturnRequest
} from "../../store/orderSlice";
import {useHistory, useParams} from "react-router-dom";
import DatePicker from "react-datepicker";
import orderReducer from "../../store";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {jqx} from "./../../../../custom_modules/jqx/jqwidgets-react-tsx/jqxgrid";
import * as ReactDOMServer from "react-dom/server";
import {toast} from "react-toastify";
import {mapOrderReturnResponseToTable} from "../../../../utils/mapper";
import JqxTable from "../../../../share-components/table/JqxTable";
import ButtonLoader from "../../../../share-components/ui/buttonLoader";
import * as SweetAlert from "sweetalert2";
import PreLoader from "../../../../share-components/ui/preLoader";
import PendingReturnJqx from "./content/pendingReturnJqx";

let gridRef
const EditPendingReturn = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    let history = useHistory();
    const orderState = useSelector(({ order }) => order.order);
    const [loading, isSetLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);


    const authData =  useSelector(({authReducer}) => authReducer);

    const dataMenu = [
        {
            type: 1,
            text: 'Pending Return Requests',
            link: '/order/pending-return-requests'
        },
        {
            type: 0,
            text: "Edit Request"
        }
    ];

    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            if (!res.error) {
                const results={
                    settings:res.payload,
                    id:null
                }
                dispatch(setSettings(res.payload));
            }
        });
    },[dispatch]);

    useEffect(() => {
        const requestParmas={
            params:{
              seller:authData.defaultSeller?.value
            },
            id:id
        }
        dispatch(showPendingReturnRequestList(requestParmas)).then(res => {
            if (!res.error) {
                dispatch(setOrderData(res.payload));
                isSetLoading(false);
            }
        });
    }, [dispatch,authData.defaultSeller]);





    const submitData = (type) =>{
        let foundErrors=0;
        let accept=[];
        let decline=[];
        let notIncluded=false;
        if(gridRef) {
            const gridData = gridRef.getdatainformation();
            if((gridRef.getselectedrowindexes()).length < 1){
                toast.error("must select at least a order ", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            else if (gridRef.getcellvalue(0, 'isRestock') && type === 1) {
                toast.error("You can not declined item when you restock ", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                for (let i = 0; i < gridData.rowscount; i++) {

                    notIncluded = false;

                    gridRef.getselectedrowindexes().forEach(element => {

                        if (element === i) {
                            notIncluded = true;
                            if (gridRef.getcellvalue(i, 'chooseReturnQuantity') === undefined || gridRef.getcellvalue(i, 'chooseReturnQuantity') === "") {
                                foundErrors++;
                            }
                            accept.push({
                                id: gridRef.getcellvalue(i, 'id'),
                                order_line_item_id: gridRef.getcellvalue(i, 'orderLineItemID'),
                                line_item_id: gridRef.getcellvalue(i, 'lineItemID'),
                                reason: gridRef.getcellvalue(i, 'reasonID'),
                                total: gridRef.getcellvalue(i, 'totalReturn'),
                                restock: gridRef.getcellvalue(i, 'isRestock') ? gridRef.getcellvalue(i, 'isRestock') : false,
                                customer_reason: gridRef.getcellvalue(i, 'customerReasonId'),
                                quantity: gridRef.getcellvalue(i, 'chooseReturnQuantity'),
                                is_declined: gridRef.getcellvalue(i, 'isDecline') ? gridRef.getcellvalue(i, 'isDecline') : false
                            });
                        }
                    });
                    if (!notIncluded)
                        decline.push(gridRef.getcellvalue(i, 'id'));
                }
                const dataSet = {
                    orderID: orderState.data.id,
                    orderNo: orderState.data.order_no,
                    id: id,
                    accept: accept,
                    decline: decline,
                    type: type
                };

                if (foundErrors > 0) {
                    toast.error("Please fill all required fields", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    SweetAlert.fire({
                        title: 'Are you sure want to process this return request?',
                        text: "Once confirmed, the return request will be sent to the system",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, Proceed',
                        cancelButtonText: 'No, cancel',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.isConfirmed) {
                                setButtonDisabled(true);
                                dispatch(storeReturnRequest(dataSet)).then(res => {
                                    if (!res.error) {
                                        setButtonDisabled(false);
                                        history.push('/order/pending-return-requests');
                                    }
                                });
                            }
                        })
                }
            }
        }else{
            
        }

    };
    const getJqx=(jqx)=>{
        gridRef=jqx;
    }

    return (
        <Fragment>
                {loading ? <>
                        <PreLoader></PreLoader>
                    </>
                    : <>
            <Breadcrumb title="Edit Pending Return Requests" data={dataMenu}/>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <h5 className="mb-2">Items from Order {orderState.data?orderState.data.order_name:""}</h5>

                            <PendingReturnJqx getGridJqx={getJqx} orderState={orderState}></PendingReturnJqx>
                                <div className="mt-2 d-flex justify-content-end">
                                    <div className="p-2">
                                        <div className="d-flex">
                                            <ButtonLoader color="primary" disabled={buttonDisabled} onClick={()=> {
                                                submitData(0)
                                            }} btntext="Save" className="btn btn-primary" />
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
                    </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(EditPendingReturn);