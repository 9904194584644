import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {toast} from "react-toastify";
import {
    addSellerApi,
    getSellerApi,
    getSellersApi,
    updateSellerApi
} from "../../../services/seller";
import {
    addDraftOrderApi,
    getDraftOrderApi,
    getDraftOrdersApi,
    searchProduct,
    updateDraftOrderApi,
    updateImportOrdersApi, generatePartnerDetails, getCustomerAddressApi, getCountryListApi
} from "../../../services/order";
import{draftOrderFilters} from "../../manage-product/product/edit/content/settings/map";

export const updateImportOrders = createAsyncThunk('orders/updateImportOrders', async (order, { dispatch, getState }) => {
    try {
        const formData = new FormData();
        formData.append(
            'file',
            order.file,
        );
        formData.append(
            'partner',
            order.partner,
        );
        const response = await updateImportOrdersApi(formData);
        const data = await response.response;
        toast.success("Import Order created successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const getDraftOrders = createAsyncThunk('orders/draft/get', async (data, {dispatch,}) => {
    const response = await getDraftOrdersApi(data);
    dispatch(setTableRowCount(response.response.count));
    return response.response.data;
});
export const productSearch = createAsyncThunk('orders/draft/search-product', async (data, {dispatch,}) => {
    const response = await searchProduct(data);
    return response.response;
});

export const getCustomerAddressData = createAsyncThunk('orders/draft/get-customer-addresses', async (data, {dispatch})=>{
    const response = await getCustomerAddressApi(data);
    return response.response;
});

export const getCountryList = createAsyncThunk('orders/draft/get-customer-addresses', async (data, {dispatch})=>{
    const response = await getCountryListApi();
    return response.response;
});


export const addDraftOrders = createAsyncThunk('orders/draft/add', async (orderData, {dispatch, getState}) => {
    try {
        const response = await addDraftOrderApi(orderData);
        const data = await response.response;
        toast.success("Draft Order created successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        return data;
    } catch (e) {

        return Promise.reject(e);
    }
});
export const getDraftOrder = createAsyncThunk('orders/draft/show', async (id, {dispatch}) => {
    const response = await getDraftOrderApi(id);
    return response.response;
});


export const updateDraftOrder = createAsyncThunk('orders/draft/update', async (orderData, {dispatch}) => {
    const response = await updateDraftOrderApi(orderData,orderData.id);
    toast.success(`Draft Order updated successfully`, {
        position: toast.POSITION.TOP_RIGHT,
    });
    return response.response;
});

export const exportPartnerDetails = createAsyncThunk('order/draft/exportPartnerData', async (data, {dispatch}) => {
    const response = await generatePartnerDetails(data,data.id);
    return response.response;
});

const draftOrderAdapter = createEntityAdapter({});

export const {selectAll: selectDraftOrder, selectById: selectDraftOrderById} = draftOrderAdapter.getSelectors(
    state => state.order.draftOrder
);

const draftOrderSlice = createSlice({
    name: 'draftOrder',
    initialState: draftOrderAdapter.getInitialState({
        id: null,
        sellerTableRow: 0,
        searchText: '',
        data: null,
        settings: {
            countries:[],
            shippings:[]
        },
        products: {
            data:[],
            pagination:{
                count:0,
                next_page:1,
                total:0,
                has_more:true,
            }
        },
        addressAddDialog: {
            component: 'buyerLead',
            type: 'new',
            formType: 1,
            props: {
                isOpen: false
            },
            data: null
        },
        searchProductDialogProps: {
            props: {
                isOpen: false
            },
            data:{searchValue:""}
        },
        discountDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        shippingDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        taxDialogProps: {
            props: {
                isOpen: false
            },
            data:{}
        },
        addDialog:{
            component: 'Import Orders',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
        filter: draftOrderFilters(),
    }),
    reducers: {
        setDraftOrderFilter: (state, action) => {
            state.filter = action.payload
        },
        setTableRowCount: (state, action) => {
            state.sellerTableRow = action.payload;
        },
        setSearchProductDialog: (state, action) => {
            state.searchProductDialogProps = action.payload
        },
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        setOrderDiscountDialog: (state, action) => {
            state.discountDialogProps = action.payload;
        },
        setShippingDiscountDialog: (state, action) => {
            state.shippingDialogProps = action.payload;
        },
        setTaxDialog: (state, action) => {
            state.taxDialogProps = action.payload;
        },
        getCountryListData: (state, action) => {
            state.settings = action.payload;
        },
        openDialog: (state, action) => {
            state.addDialog = {
                component: 'Import Orders',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        closeDialog: (state, action) => {
            state.addDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
        openEditAddressDialog: (state, action) => {
            state.addressAddDialog = {
                component: 'buyerLead',
                type: 'edit',
                formType: 1,
                props: {
                    isOpen: true
                },
                data: action.payload.data
            };
        },
        closeNewAddressDialog: (state, action) => {
            state.addressAddDialog = {
                component: 'buyerLead',
                type: 'edit',
                formType: 1,
                props: {
                    isOpen: false
                },
                data: null
            }
        },
    },
    extraReducers: {
        [getDraftOrders.fulfilled]: draftOrderAdapter.setAll,
        // [updateStaff.fulfilled]: staffsAdapter.setAll
        // [deleteStaff.fulfilled]: staffsAdapter.removeOne,
    }
});

export const {setDraftOrderFilter, setProducts, setTableRowCount, setSearchProductDialog,setOrderDiscountDialog,setShippingDiscountDialog,setTaxDialog,openDialog,closeDialog, openEditAddressDialog, closeNewAddressDialog,getCountryListData} =
    draftOrderSlice.actions;

export default draftOrderSlice.reducer;
