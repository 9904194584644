import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {
    getProductPlatformsApi, addProductPlatformApi, updateProductPlatformApi, deleteProductPlatformApi
} from "../../../services/manage-product/setting/productPlatformService";
import {toast} from "react-toastify";

export const getAllProductPlatform = createAsyncThunk('productPlatform/getProductPlatform', async (data,{dispatch}) => {
    const response = await getProductPlatformsApi(data);
    return response.response;
});

export const addProductPlatform = createAsyncThunk('productPlatform/addProductPlatform', async (platform, {dispatch,getState}) => {
    try {
        const response = await addProductPlatformApi(platform);
        const data = await response.response;
        dispatch(getAllProductPlatform());
        toast.success("Add product platform successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const updateProductPlatform = createAsyncThunk('productPlatform/updateProductPlatform', async (platform, {dispatch,getStatus}) => {
    try {
        const response = await updateProductPlatformApi(platform, platform.id);
        const data = await response.response;
        dispatch(getAllProductPlatform());
        toast.success("Update product platform successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

export const deleteProductPlatform = createAsyncThunk('productPlatform/deleteProductPlatform', async (id, {dispatch,getStatus}) => {
    try {
        const response = await deleteProductPlatformApi(id);
        const data = await response.response;
        dispatch(getAllProductPlatform());
        toast.success("Deleted product platform successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        return data;
    } catch (e) {
        return Promise.reject(e);
    }
});

const productPlatformAdapter = createEntityAdapter({});

export const {selectAll: selectProductPlatform, selectById: selectProductPlatformById} = productPlatformAdapter.getSelectors(
    state => state.product.productPlatform
);

const productTypeSlice = createSlice({
    name: 'productPlatform',
    initialState: productPlatformAdapter.getInitialState({
        searchText: '',
        addDialog: {
            component: 'ProductPlatform',
            type: 'new',
            props: {
                open: false
            },
            data: null
        },
       
    }),
    reducers: {
        openNewDialog: (state, action) => {
            state.addDialog = {
                component: 'ProductPlatform',
                type: 'new',
                props: {
                    isOpen: true
                },
                data: null
            };
        },
        openEditDialog: (state, action) => {
            state.addDialog = {
                type: 'edit',
                props: {
                    isOpen: true
                },
                data: action.payload
            };
        },
        closeNewDialog: (state, action) => {
            state.addDialog = {
                type: 'new',
                props: {
                    isOpen: false
                },
                data: null
            };
        },
       
       
    },
    extraReducers: {
        [getAllProductPlatform.fulfilled]: productPlatformAdapter.setAll
    }
});

export const {
    openNewDialog,closeNewDialog,openEditDialog,
} = productTypeSlice.actions;

export default productTypeSlice.reducer;

