import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    ButtonDropdown,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Row,Input
} from "reactstrap";
import CommonDataTable from "../../../share-components/table/commonDataTable";
import {mapReturnResponseToTable, mapSearchAutoSuggestion} from "../../../utils/mapper";
import {useDispatch, useSelector} from "react-redux";
import withReducer from "../../../store/withReducer";
// import useNavigate from 'react-router-dom';
import {
    exportReturnDetails,
    exportReturnGroupDetails,
    getOrderSettings,
    getReturns,
    sendReturnItemShopify,
    setRefundModal,
    setReturnData,
    setSettings, selectOrder, setReturnOrderFilter
} from "../store/orderSlice";
import {useHistory} from "react-router-dom";
import orderReducer from "../store";
import SweetAlert from "sweetalert2";
import PreLoader from "../../../share-components/ui/preLoader";
import DatePicker from "../../../share-components/ui/DateRangePicker";
import {Form} from "react-bootstrap";
import {DateRangePicker} from "rsuite";
import Select from "react-select";
import moment from "moment";
import {returnOrderFilters} from "../../manage-product/product/edit/content/settings/map";
import {sellerAutoSuggestionApi} from "../../../services/manage-product/productService";
import {setProductFilter} from "../../manage-product/store/prodcutSlice";
import AsyncSelect from "react-select/async";
import RefundModal from "./content/refundModal";
import {getBrands} from "../../manage-product/store/inventorySlice";

const {afterToday} = DateRangePicker;

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 50,
        border: 'none',
    })
};

const Return = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const orderReturnData = useSelector(selectOrder);
    const submitTypes = ["submit", "update", "save"];
    const [differentPage, setDifferentPage] = useState(!submitTypes.includes(history.location.state?.from));
    const [loading, isSetLoading] = useState(history.location.state?.from !== "update" || orderReturnData.length < 1);
    const [isFilterable, setIsFilterable] = useState((history.location.state?.from !== "update" || orderReturnData.length < 1));
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [brands, setBrands] = useState(null);
    const [brandsVal, setBrandsVal] = useState(null);
    const [reason, setReason] = useState(null);
    const [seller, setSeller] = useState(null);
    const [exchangeType, setExchangeType] = useState(null);
    const [refundStatus, setRefundStatus] = useState(null);
    const [customerReason, setCustomerReason] = useState(null);
    const [checkBoxData, setCheckBox] = useState({penatly: []});
    const [isPenalty, setIsPenalty] = useState(false);
    // const [loading, isSetLoading] = useState(true);
    const orderState = useSelector(({order}) => order.order);
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const [dateRange, setDateRange] = useState([sevenDays, TodayDate]);
    const userState = useSelector(({authReducer}) => authReducer.data);
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const actionToggle = () => setActionDropdownOpen(prevState => !prevState);
    const [importDdpFile, setImportDdpFile] = useState(false);

    const [order, setOrder] = useState(null);
    const [timer, setTimer] = useState(null);
    const [btnDisable, setBtnDisable] = useState(false);
    const authData =  useSelector(({authReducer}) => authReducer);
    

    // useEffect(()=>{
    //     setSeller(authData.defaultSeller)
    // },[authData.defaultSeller])

    useEffect(() => {

        if (differentPage) {
            dispatch(setReturnOrderFilter(returnOrderFilters(authData.defaultSeller)));
        }
    }, []);

    useEffect(()=>{
        if (!differentPage && (isFilterable ||  ((isFilterable)&&authData.defaultSeller?.value != orderState.filters.seller?.value))) {
            
            dispatch(setReturnOrderFilter({
                ...orderState.filters,
                seller: authData.defaultSeller,
                currentPage: 1
            }));
           
        }
    },[authData.defaultSeller])
    

    const [startDate, endDate] = dateRange;
    const dataMenu = [
        {
            type: 0,
            text: 'Returns',
        }
    ];

    let tableColumns = [
        {
            name: 'Order #',
            width: '95px',
            selector: row => row.orderName,
            sortable: false,
            cell: (row) => <a className="text-primary" style={{cursor: "pointer"}} onClick={onView}
                              id={row.id}>{row.orderName}</a>,
            center: false,
        },

    ];

    if (userState.user.role.name !== "Seller") {
        tableColumns = [
            ...tableColumns,
            {
                name: 'SKU',

                selector: row => row.sku,
                sortable: false,
                center: false,
                width: '275px',
            },
            {
                name: 'Seller',

                selector: row => row.seller,
                sortable: false,
                center: false,
                width: '110px',

            },
        ]
    }

    tableColumns = [
        ...tableColumns,
        {
            name: 'Total (£)',

            selector: row => row.total.toFixed(2),
            sortable: false,
            right: true,
            width: '80px',
        },
        {
            name: 'Return Date',
            selector: row => row.date,
            sortable: false,
            center: false,
            width: '130px',
        },
        // {
        //     name: 'Order Quantity',
        //     // width: "15%",
        //     selector: row => row.ordered_quantity,
        //     sortable: true,
        //     right: true,
        // },
        // {
        //     name: 'Customer Return Quantity',
        //     // width: "20%",
        //     selector: row => row.customer_return_quantity,
        //     sortable: true,
        //     right: true,
        // },
        // {
        //     name: 'Seller Amended Quantity',
        //     // width: "20%",
        //     selector: row => row.approved_return_quantity,
        //     sortable: true,
        //     right: true,
        // },

    ];

    if (userState.user.role.name === "Seller") {
        tableColumns = [
            ...tableColumns,
            {
                name: 'Order Quantity',

                selector: row => row.ordered_quantity,
                sortable: true,
                right: true,
            },
            {
                name: 'Customer Return Quantity',
                selector: row => row.customer_return_quantity,
                sortable: true,
                right: true,
            },
            {
                name: 'Seller Amended Quantity',
                selector: row => row.approved_return_quantity,
                sortable: true,
                right: true,
            },
            {
                name: 'Status',
                width: "20%",
                selector: row => row.status,
                sortable: true,
                center: false,
            },
            
        ]
    }

    if (userState.user.role.name !== "Seller") {
        tableColumns = [
            ...tableColumns,

            {
                name: 'Status Date',
                selector: row => row.orderRefundDate,
                sortable: true,
                center: false,


            },
            {
                name: 'status',
                selector: row => row.status,

                sortable: true,
                center: false,
            },
            // {
            //     name: 'Customer Reason',
            //     selector: row => row.customer_reason,
            //     width: "10%",
            //     sortable: true,
            //     center: false,
            // },
            {
                name: 'Type',
                selector: row => row.type,
                sortable: false,
                center: false,
                width: '100px',
            },
            {
                name: 'Re Stock',
                selector: row => {
                    return row.is_restock ? "Yes" : "No"
                },
                sortable: false,
                center: false,
                width: '90px',

            },
            {
                name: 'Seller Declined',
                selector: row => {
                    return row.is_declined ? "Yes" : "No"
                },
                sortable: false,
                center: false,
                width: '90px',

            },
            {
                name: 'Penalty',
                selector: row => {
                    return row.is_penalty ? "Yes" : "No"
                },
                sortable: false,
                center: true,
                width: '90px',
                // cell: (row) => row.is_return != 3 ?
                //     <input disabled={row.status_id !== 1 ? 'disabled' : ''} onChange={(e) => {
                //         checkBoxChange(row, e.target.checked);
                //         // setIsPenalty(e.target.checked);
                //     }} checked={row.is_penalty} type={"checkbox"}/> : '',
            },
            {
                name: 'Declined',
                selector: row => {
                    return row.is_admin_declined ? "Yes" : "No"
                },
                sortable: false,
                center: true,
                width: '100px',
            },
            {
                name: 'Action',
                width: "100px",
                selector: row => row.id,
                cell: (row) => row.status_id === 1 && row.is_return != 3 ?
                    <a className="text-primary" style={{cursor: "pointer"}} onClick={() => {
                        onAction(row)
                    }} id={row.id}><i className="fa fa-reply"></i></a> : "",
                sortable: false,
                center: false,
            }

        ]
    }

    useEffect(() => {
        const data = {
            value: '',
            // seller_id: seller
            seller_id: orderState.filters.seller? orderState.filters.seller.value:'',
        }
        dispatch(getBrands(data)).then(res=>{
            setBrandsVal(res.payload);
        });
    },[dispatch, orderState.filters.seller])

    // useEffect(() => {
    //     getData();
    // }, [page, startDate, endDate, brands, reason, dateRange, seller, exchangeType, refundStatus, customerReason, pageSize, order]);

    // const pagination = (e) => {
    //     setPageSize(e)
    // }
    const pageChange = (e) => { 
        dispatch(setReturnOrderFilter({...orderState.filters, currentPage:e}))
    }
    const pagination = (e) => {
        dispatch(setReturnOrderFilter({...orderState.filters, currentPage: 1, pageSize:e}))
    }

    useEffect(() => {
        dispatch(getOrderSettings()).then(res => {
            dispatch(setSettings(res.payload))
        });
    }, [dispatch]);

    // const getData = () => {
    //     const data = {
    //         page: page,
    //         pageSize: pageSize,
    //         brands,
    //         start_date: moment(startDate).format('YYYY-MM-DD'),
    //         end_date: moment(endDate).format('YYYY-MM-DD'),
    //         reason,
    //         // seller,
    //         seller:seller && seller.value ? seller.value : '',
    //         exchangeType,
    //         refundStatus,
    //         customerReason,
    //         order
    //     }
    //     dispatch(getReturns(data)).then(res => {
    //         if (!res.error) {
    //             dispatch(setReturnData(res.payload));
    //             isSetLoading(false);
    //         }
    //     });

    // }
    
    const getData=()=>{
        const filters = orderState.filters;
        if (isFilterable) {
            const filters = orderState.filters;

            
            if (differentPage && JSON.stringify(filters) != JSON.stringify(returnOrderFilters(authData.defaultSeller))) {
                setDifferentPage(false);
            } else {
                dispatch(getReturns({
                    page:filters.currentPage,
                    pageSize: filters.pageSize,
                    start_date: moment(startDate).format('YYYY-MM-DD'),
                    end_date: moment(endDate).format('YYYY-MM-DD'),
                    seller: orderState.filters.seller? orderState.filters.seller.value:'',
                    brands: orderState.filters.brands? orderState.filters.brands.id:'',
                    reason: orderState.filters.reason? orderState.filters.reason.id:'',
                    exchangeType: orderState.filters.exchangeType? orderState.filters.exchangeType.id:'',
                    refundStatus: orderState.filters.refundStatus? orderState.filters.refundStatus.id:'',
                    customerReason: orderState.filters.customerReason? orderState.filters.customerReason.id:'',
                    order:orderState.filters.order,
                })).then(res=> {
                    dispatch(setReturnData(res.payload));
                    isSetLoading(false);
                })
                setDifferentPage(false);
            }
        }
        setIsFilterable(true);

    }
    useEffect(() => {
        getData();
    },[orderState.filters.currentPage, orderState.filters.pageSize, startDate, endDate, orderState.filters.seller, orderState.filters.brands,
        orderState.filters.reason,orderState.filters.exchangeType, orderState.filters.refundStatus, orderState.filters.customerReason, orderState.filters.order]);


    const checkBoxChange = (row, isChecked) => {
        let checkArray = checkBoxData['penatly'];
        if (isChecked) {
            checkArray.push(row.id);
        } else {
            if (checkArray.includes(row.id)) {
                let packIndex = checkArray.indexOf(row.id);
                checkArray.splice(packIndex, 1);
            }
        }

        setCheckBox(prevState => ({
            ...prevState,
            'penatly': checkArray
        }));
    }
    const onAction = (row) => {
        dispatch(setRefundModal({props: {isOpen: true}, data: row}));
    }
    const onRefund = (data) => {
        setBtnDisable(true);
        const postData = {
            'order_refund_id': data.data.id,
            'is_penalty': data.isPenalty,
            'is_declined': data.isDecline,
        }
        dispatch(sendReturnItemShopify(postData)).then(res => {
            if (!res.error) {
                getData();
                dispatch(setRefundModal({props: {isOpen: false}, data: null}));
            }
            setBtnDisable(false);
        });
    }
    const onView = (state) => {
        history.push('/order/return/' + state.target.id + '/view');
    }

    const exportReturn = () => {
        setBtnDisable(true);
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            // reason: reason ? reason : '',
            // seller: seller?.value,
            // exchangeType: exchangeType ? exchangeType : '',
            // refundStatus: refundStatus ? refundStatus : '',
            // customerReason: customerReason ? customerReason : '',
            // brands: brands ? brands : '',
            reason: orderState.filters.reason ? orderState.filters.reason.id : '',
            seller: orderState.filters.seller? orderState.filters.seller.value:'',
            exchangeType: orderState.filters.exchangeType ? orderState.filters.exchangeType.id : '',
            refundStatus: orderState.filters.refundStatus ? orderState.filters.refundStatus.id : '',
            customerReason: orderState.filters.customerReason ? orderState.filters.customerReason.id : '',
            brands: orderState.filters.brands ? orderState.filters.brands.id : '',
            // pageType: 'report'
        }
        // const outputFilename = `Admin_Return_Report_${(new Date().toJSON().slice(0, 10))}.csv`;
        let outputFilename;

        if (userState.user.role.name !== "Seller") {
        outputFilename = `Admin_Return_Report_${(new Date().toJSON().slice(0, 10))}.csv`;
        } else {
        outputFilename = `Seller_Return_Report_${(new Date().toJSON().slice(0, 10))}.csv`;
        }

        dispatch(exportReturnDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false)
        });
    }

    const exportGroupReturn = () => {
        setBtnDisable(true)
        setImportDdpFile(false)
        const data = {
            start_date: moment(startDate).format('YYYY-MM-DD'),
            end_date: moment(endDate).format('YYYY-MM-DD'),
            reason: orderState.filters.reason ? orderState.filters.reason.id : '',
            // seller: seller ? seller : '',
            seller: orderState.filters.seller? orderState.filters.seller.value:'',
            exchangeType: orderState.filters.exchangeType ? orderState.filters.exchangeType.id : '',
            refundStatus: orderState.filters.refundStatus ? orderState.filters.refundStatus.id : '',
            customerReason: orderState.filters.customerReason ? orderState.filters.customerReason.id : '',
            brands: orderState.filters.brands ? orderState.filters.brands.id : '',
            pageType: 'report'
        }
        const outputFilename = `Return_Group_report_${(new Date().toJSON().slice(0, 10))}.csv`;
        dispatch(exportReturnGroupDetails({...data})).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.payload]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            setBtnDisable(false)
        });
    }

    const sellerPromiseOption = (inputValue) =>
        new Promise((resolve) => {
            const data = {
                value: inputValue
            }
            setTimeout(() => {
                resolve(mapSearchAutoSuggestion(sellerAutoSuggestionApi(data)))
            });
        });
    return (
        <Fragment>
            {loading ?
                <>
                    <PreLoader></PreLoader>
                </>
                :
                <>
                    <Breadcrumb title="Returns" data={dataMenu}/>
                    <Container fluid={true} className="product-wrapper">
                        <div className="product-grid">
                            <div className="feature-products">

                                <Card>
                                    <Col sm="12">
                                        <Row>
                                            <Col sm="4">
                                                <div className='p-3'>
                                                    <DatePicker
                                                        dateRange={dateRange}
                                                        dateRangeChange={(date) => {
                                                            setDateRange(date)
                                                        }}
                                                        defaultValue={[TodayDate, sevenDays]}
                                                        disabledDate={afterToday()}
                                                    />
                                                </div>
                                            </Col>

                                            <Col sm="3">
                                                <div className='p-3'>
                                                    {userState.user.role.name !== "Seller" &&
                                                        <AsyncSelect
                                                            className="select2-filter"
                                                            isClearable={true}
                                                            menuPortalTarget={document.body}
                                                            defaultOptions
                                                            // value={seller}
                                                            value={orderState.filters.seller}
                                                            placeholder="select Seller"
                                                            loadOptions={sellerPromiseOption}
                                                            styles={{
                                                                ...customStyles,
                                                                menuPortal: base => ({...base, zIndex: 100})
                                                            }}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            onClear={(e) => {

                                                            }}
                                                            onChange={(e) => {
                                                                // setSeller(e);
                                                                dispatch(setReturnOrderFilter({
                                                                    ...orderState.filters,
                                                                    seller:e ? e : null,
                                                                    currentPage: 1,
                                                                }));
                                                               
                                                            }}

                                                        />
                                                    }
                                                </div>
                                            </Col>

                                            <Col sm="3">
                                                <div className='p-3'>
                                                    {userState.user.role.name !== "Seller" &&
                                                        <Select
                                                            placeholder={<div>Seller Brand</div>}
                                                            className="select2-filter"
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.id}
                                                            isClearable
                                                            cacheOptions
                                                            styles={customStyles}
                                                            options={brandsVal}
                                                            defaultValue={orderState.filters.brands}
                                                            onChange={(e) => {
                                                                // setBrands(e ? e.id : null)
                                                                dispatch(setReturnOrderFilter({
                                                                    ...orderState.filters,
                                                                    brands:e ? e : null,
                                                                    currentPage: 1,
                                                                }));
                                                            }}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}

                                                        />
                                                    }
                                                </div>
                                            </Col>

                                            <Col sm="2">
                                                <div className='float-sm-right'
                                                     style={{marginBottom: "1.5rem", marginTop: "1.5rem"}}>
                                                    <ButtonDropdown isOpen={actionDropdownOpen}
                                                                    toggle={actionToggle}>
                                                        <DropdownToggle caret color="primary"
                                                                        className="btn btn-primary btn-square">
                                                            {/* Export */}
                                                            {btnDisable ? ( 
                                                                        <i className="fa fa-spinner fa-spin fa-lg spinner mr-1" />
                                                                    ) : (
                                                                        'Export'
                                                                    )}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => {
                                                                exportReturn()

                                                            }
                                                            }>Return</DropdownItem>
                                                            {userState.user.role.name !== "Seller" &&
                                                                <DropdownItem onClick={() => {
                                                                    exportGroupReturn()

                                                                }
                                                                }>Return group</DropdownItem>}
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Card>

                                <Row>
                                    <Col sm="">
                                        <Select
                                            placeholder={<div>Admin Reason</div>}
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            defaultValue={orderState.filters.reason}
                                            options={orderState.settings.returnReasons}
                                            onChange={(e) => {
                                                // setReason(e ? e.id : null)
                                                dispatch(setReturnOrderFilter({
                                                    ...orderState.filters,
                                                    reason:e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}


                                        />
                                    </Col>
                                    <Col sm="">
                                        <Select
                                            placeholder={<div>Customer Reason</div>}
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            options={orderState.settings.returnReasonsCustomer}
                                            defaultValue={orderState.filters.customerReason}
                                            onChange={(e) => {
                                                // setCustomerReason(e ? e.id : null)
                                                dispatch(setReturnOrderFilter({
                                                    ...orderState.filters,
                                                    customerReason:e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}

                                        />
                                    </Col>
                                    <Col sm="">
                                        <Select
                                            placeholder={<div>Exchange Types</div>}
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            defaultValue={orderState.filters.exchangeType}
                                            options={orderState.settings.exchangeTypes}
                                            onChange={(e) => {
                                                // setExchangeType(e ? e.id : null)
                                                dispatch(setReturnOrderFilter({
                                                    ...orderState.filters,
                                                    exchangeType:e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}

                                        />
                                    </Col>
                                    <Col sm="" style={{marginBottom: "1rem"}}>
                                        <Select
                                            placeholder={<div>Refund Status</div>}
                                            className="select2-filter"
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            isClearable
                                            cacheOptions
                                            styles={customStyles}
                                            options={orderState.settings.refundStatus}
                                            defaultValue={orderState.filters.refundStatus}
                                            onChange={(e) => {
                                                // setRefundStatus(e ? e.id : null)
                                                dispatch(setReturnOrderFilter({
                                                    ...orderState.filters,
                                                    refundStatus:e ? e : null,
                                                    currentPage: 1,
                                                }));
                                            }}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}

                                        />
                                    </Col>
                                    <Col sm="">
                                        <Form  onSubmit={(e)=>{
                                            e.preventDefault();
                                        }}>
                                            <FormGroup className="m-0">
                                                <Input
                                                    name="order"
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={orderState.filters.order}
                                                    onChange={(e)=>{
                                                        clearTimeout(timer);
                                                        const newTimer = setTimeout(()=>{
                                                            // setOrder(e.target.value);
                                                            dispatch(setReturnOrderFilter({
                                                                ...orderState.filters,
                                                                order:e.target.value,
                                                                currentPage: 1,
                                                            }));
                                                        },500);
                                                        setTimer(newTimer)
                                                    }}
                                                    placeholder="Search Order id"
                                                />
                                                <i className="fa fa-search"></i>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                    
                                    {/*<Col sm="3">*/}
                                    {/*    {userState.user.role.name !== "Seller" &&*/}
                                    {/*        <Select*/}
                                    {/*            placeholder={<div>Seller Brand</div>}*/}
                                    {/*            className="select2-filter"*/}
                                    {/*            getOptionLabel={(option) => option.name}*/}
                                    {/*            getOptionValue={(option) => option.id}*/}
                                    {/*            isClearable*/}
                                    {/*            cacheOptions*/}
                                    {/*            styles={customStyles}*/}
                                    {/*            options={brandsVal}*/}
                                    {/*            onChange={(e) => {*/}
                                    {/*                setBrands(e ? e.id : null)*/}
                                    {/*            }}*/}
                                    {/*            components={{*/}
                                    {/*                IndicatorSeparator: () => null*/}
                                    {/*            }}*/}

                                    {/*        />*/}
                                    {/*    }*/}
                                    {/*</Col>*/}
                                </Row>
                                <Row>
                                    <Col sm="12" className="mt-3">
                                        <Card>
                                            <CardBody>
                                                <div className="table-responsive product-table">
                                                    <CommonDataTable
                                                        headerColumns={tableColumns}
                                                        gridData={orderState.returnData ? mapReturnResponseToTable(orderState.returnData, checkBoxData) : ""}
                                                        noAction
                                                        paginationServer
                                                        paginationTotalRows={orderState.orderTableRow}
                                                        paginationRowsPerPageOptions={[10,25,50,100]}
                                                        onChangeRowsPerPage={pagination}
                                                        // onChangePage={page => setPage(page)}
                                                        onChangePage={pageChange}
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <RefundModal orderState={orderState} onRefund={onRefund} btnDisable={btnDisable}></RefundModal>
                    </Container>
                </>}
        </Fragment>
    );
}

export default withReducer([{order: orderReducer}])(Return);